import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Link, Typography } from '@mui/material';

import useStyles from '../PrivacyPolicy/styles';

const PersonalData: React.FC = () => {
	const classes = useStyles();
	return (
		<section id="price-plans" className={classes.root}>
			<Container maxWidth="lg">
				<Typography className={classes.sectionTitleText}>Обробка персональних даних</Typography>
				<Typography className={classes.textDefault}>
					Ваша конфіденційність має для нас величезне значення. Ця сторінка містить важливу інформацію про те, як ми збираємо, використовуємо та захищаємо ваші персональні дані на цьому веб-сайті.
				</Typography>

				<Typography className={classes.subTitle1}>
					Згода на обробку даних
				</Typography>
				<Typography className={classes.textDefault}>
					Користуючись нашим веб-сайтом, ви погоджуєтесь на обробку своїх персональних даних відповідно до нашої
					{' '}
					<Link to="/privacy-policy" component={RouterLink} className={classes.link}>Політики конфіденційності</Link>
					.
				</Typography>

				<Typography className={classes.subTitle1}>
					Збір і використання даних
				</Typography>
				<Typography className={classes.textDefault}>
					Ми можемо збирати різні типи персональних даних, які ви надаєте нам за власним бажанням. Це може включати ваше ім'я, контактну інформацію та іншу інформацію, яка необхідна для виконання наших послуг.
				</Typography>
				<Typography className={classes.textDefault}>
					Ми використовуємо ваші дані для покращення наших послуг та надання вам кращого досвіду взаємодії з нашим сайтом. Ми робимо все можливе для захисту цих даних від несанкціонованого доступу та втрати.
				</Typography>

				<Typography className={classes.subTitle1}>
					Захист ваших даних
				</Typography>
				<Typography className={classes.textDefault}>
					Ми прагнемо забезпечити найвищий рівень захисту вашої конфіденційності. Ми використовуємо різні технологічні та організаційні заходи для запобігання несанкціонованому доступу до ваших персональних даних, а також для захисту їх від втрати чи руйнування.
				</Typography>

				<Typography className={classes.subTitle1}>
					Ваші права
				</Typography>
				<Typography className={classes.textDefault}>
					Як користувач цього веб-сайту, у вас є певні права щодо вашої персональної інформації. Ви можете в будь-який момент звернутися до нас з запитом на доступ до ваших даних, їх виправлення, видалення чи обмеження обробки. Ви також маєте право відкликати свою згоду на обробку даних.
				</Typography>

				<Typography className={classes.subTitle1}>
					Зв'яжіться з нами
				</Typography>
				<Typography className={classes.textDefault}>
					Якщо у вас є питання або запити щодо обробки ваших персональних даних або нашої Політики конфіденційності, будь ласка, зв'яжіться з нами за адресою
					{' '}
					<Link href="mailto:lets.talk@wootitude.com" className={classes.emailLink}>lets.talk@wootitude.com</Link>
					.
				</Typography>
			</Container>
		</section>
	);
};

export default PersonalData;
