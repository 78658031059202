import { ThemeOptions, createTheme } from '@mui/material/styles';
import { alpha } from '@mui/material';

import palette from './palette';
import typography from './typography';
import MuiButton from './overrides/MuiButton';
import MuiAccordion from './overrides/MuiAccordion';
import MuiTextField from './overrides/MuiTextField';
import MuiSelect from './overrides/MuiSelect';
import MuiDialog from './overrides/MuiDialog';

const theme: ThemeOptions = {
	typography,
	palette,
	components: {
		MuiButton,
		MuiAccordion,
		MuiTextField,
		MuiSelect,
		MuiDialog,
		MuiInput: {
			styleOverrides: {
				root: ({ theme }) => ({
					color: palette.primary?.contrastText,
					fontFamily: 'Helvetica Neue, sans-serif',
					fontSize: '14px',

					'& .MuiSvgIcon-root, & .MuiSelect-icon': {
						color: palette.primary?.contrastText,
					},
				}),
				underline: () => ({
					'&, &:hover, &.Mui-error': {
						'&:not(.Mui-disabled):before': {
							borderBottom: '1px solid rgba(255, 255, 252, 0.30)',
						},
						'&:after': {
							borderBottom: `1px solid ${palette.primary?.contrastText}`,
						},
					},
				}),
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: () => ({
					'&, &.Mui-focused, &.Mui-error': {
						color: 'rgba(255, 255, 252, 0.60)',
					},
				}),
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					'&.Mui-error': {
						color: '#FF4B4B',
						fontSize: '12px',
					}
				},
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					margin: '0 0 24px',
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				head: ({ theme }) =>( {
					color: alpha(theme.palette.secondary.contrastText, .5),
					fontWeight: 700,
					fontSize: '18px',
				}),
				body: ({ theme }) => ({
					color: theme.palette.secondary.contrastText,
					fontSize: '14px',
				}),
			},
		},
	},
};

export default createTheme(theme);
