import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import { Popover, Box, Container, IconButton, Link, MenuItem, Toolbar } from '@mui/material';
import { MenuRounded as MenuRoundedIcon, CloseRounded as CloseRoundedIcon } from '@mui/icons-material';

import Logo from '../Logo';
import SocialIconsList from '../SocialIconsList';
import useStyles from './styles';

const links = [
	{ label: 'Головна', href: '/#main' },
	{ label: 'Про курс', href: '/#about' },
	{ label: 'Тарифні плани', href: '/#price-plans' },
	{ label: 'Відгуки', href: '/#testimonials' },
	{ label: 'FAQ', href: '/#faq' },
];

const Header = () => {
	const location = useLocation();
	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

	const classes = useStyles();

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	useEffect(() => {
		const hash = location.hash.slice(1);
		if (hash) {
			const el = document.getElementById(hash);
			el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, [location]);

	return (
		<div className={classes.wrapper}>
			<AppBar className={classes.root} elevation={0}>
				<Container maxWidth="xl">
					<Toolbar disableGutters className={classes.container}>
						<Logo />
						<Box className={classes.menuIcon}>
							<IconButton
								size="large"
								aria-label="menu-appbar"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="inherit"
							>
								<MenuRoundedIcon />
							</IconButton>
							<Popover
								id="menu-appbar"
								keepMounted
								anchorEl={anchorElNav}
								open={Boolean(anchorElNav)}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								marginThreshold={0}
								onClose={handleCloseNavMenu}
								className={classes.mobileMenu}
							>
								<div className={classes.mobileMenuHead}>
									<IconButton
										size="large"
										aria-label="menu-appbar"
										aria-controls="menu-appbar"
										aria-haspopup="true"
										onClick={handleCloseNavMenu}
										className={classes.closeMenuIcon}
									>
										<CloseRoundedIcon />
									</IconButton>
								</div>
								{links.map(({ label, href }) => (
									<MenuItem key={label} onClick={handleCloseNavMenu} className={classes.mobileMenuItem}>
										<Link to={href} component={RouterLink} className={classes.mobileMenuLink}>{label}</Link>
									</MenuItem>
								))}
								<div className={classes.socialList}>
									<SocialIconsList size="small" />
								</div>
							</Popover>
						</Box>
						<Box className={classes.menu}>
							{links.map(({ label, href }) => (
								<Link
									variant="body2"
									to={href}
									key={label}
									component={RouterLink}
									className={classes.link}
									onClick={handleCloseNavMenu}
								>
									{label}
								</Link>
							))}
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
		</div>
	)
};

export default Header;