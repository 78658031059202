import { SvgIcon } from '@mui/material';

const FacebookIcon = () => (
	<SvgIcon width="40" height="40" viewBox="0 0 40 40" style={{ width: '40px', height: '40px' }}>
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M21.6571 20.3648H25.3793L25.9637 16.5605H21.6563V14.4813C21.6563 12.901 22.1696 11.4996 23.6389 11.4996H26V8.17974C25.5852 8.12338 24.7078 8 23.05 8C19.5882 8 17.5587 9.8393 17.5587 14.0297V16.5605H14V20.3648H17.5587V30.821C18.2634 30.9276 18.9773 31 19.7101 31C20.3724 31 21.0189 30.9391 21.6571 30.8522V20.3648Z" fill="currentColor"/>
		</svg>
	</SvgIcon>
);

export default FacebookIcon;
