import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Link } from '@mui/material';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
	logo: {
		width: '163px',
		height: '24px',
		flexShrink: 0,

		[theme.breakpoints.up('md')]: {
			width: '203px',
			height: '30px',
		}
	},
	img: {
		maxWidth: '100%',
		maxHeight: '100%',
	},
}));

const Logo: React.FC = () => {
	const classes = useStyles();
	return (
		<div className={classes.logo}>
			<Link to="/" component={RouterLink}>
				<img src="/assets/logo.svg" alt="Logo" className={classes.img}/>
			</Link>
		</div>
	);
};

export default Logo;
