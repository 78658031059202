import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import AttachMoneySharpIcon from '@mui/icons-material/AttachMoneySharp';

import { PricePlan } from '../../../../../interfaces';
import BenefitsList from '../BenefitsList';
import useStyles from './styles';

interface Props {
	plan: PricePlan;
	initialOpen: boolean;
	detailBtnHidden: boolean;
	selectPlan(plan: PricePlan): void;
}

const PlanItem: React.FC<Props> = ({ plan, initialOpen, detailBtnHidden, selectPlan }: Props) => {
	const { id, title, cost, benefits } = plan;
	const classes = useStyles();
	const [open, setOpen] = useState(initialOpen);

	return (
		<li key={id} className={classes.priceListItem}>
			<Typography className={classes.priceItemTitle}>{title}</Typography>
			<div className={classes.priceItemPrice}>
				<AttachMoneySharpIcon className={classes.priceItemPriceCurrency} />
				<Typography className={classes.priceItemPriceNumber}>{cost}</Typography>
			</div>
			{(open || detailBtnHidden) && <BenefitsList benefits={benefits} />}
			<Button variant="contained" className={classes.selectBtn} onClick={() => selectPlan(plan)}>Обрати</Button>
			<Button
				variant="text"
				size="small"
				className={classes.showDetailsBtn}
				onClick={() => setOpen(val => !val)}
			>
				{open ? 'Приховати деталі' : 'Показати деталі'}
			</Button>
		</li>
	);
}

export default PlanItem;
