import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) => ({
	root: {
		'&.MuiAppBar-root': {
			backgroundColor: theme.palette.primary.main,

			[theme.breakpoints.up('md')]: {
				backgroundColor: 'transparent',
				position: 'relative',
			},
		},
	},
	wrapper: {
		minHeight: '84px!important',

		[theme.breakpoints.up('md')]: {
			minHeight: 'auto!important',
		},
	},
	container: {
		display: 'flex',
		justifyContent: 'center',
		minHeight: '84px!important',

		[theme.breakpoints.up('md')]: {
			minHeight: 'auto!important',
			padding: '50px 0 0',
		},
	},
	menuIcon: {
		display: 'flex',
		margin: '0 0 0 auto',

		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	mobileMenuHead: {
		color: theme.palette.primary.contrastText,
		minHeight: '84px',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		padding: `0 ${theme.spacing(2)}`,
		margin: '0 0 40px',
	},
	closeMenuIcon: {
		'&.MuiButtonBase-root': {
			color: theme.palette.primary.contrastText,
		},
	},
	mobileMenu: {
		display: 'block',

		[theme.breakpoints.up('md')]: {
			display: 'none',
		},

		'& .MuiBackdrop-root': {
			backgroundColor: 'rgba(0,0,0,.8)',
		},
		'& .MuiPopover-paper': {
			position: 'fixed',
			top: '0!important',
			right: '0px!important',
			left: 'auto!important',
			bottom: '0!important',
			width: '280px',
			maxHeight: '100vh',
			minHeight: '100vh',
			backgroundColor: theme.palette.primary.main,
		},
	},
	mobileMenuItem: {
		padding: 0,
		width: '100%',

		'&:not(:last-child)': {
			marginBottom: '4px',
		},
	},
	mobileMenuLink: {
		'&.MuiLink-root': {
			display: 'block',
			width: '100%',
			fontSize: '18px',
			color: theme.palette.primary.contrastText,
			fontFamily: 'Lato, sans-serif',
			textDecoration: 'none',
			padding: '6px 16px',
		}
	},
	socialList: {
		position: 'absolute',
		left: 0,
		right: 0,
		bottom:0,
		display: 'flex',
		justifyContent: 'center',
		padding: '40px 0',
	},
	menu: {
		margin: '0 0 0 auto',
		display: 'none',
		gap: '60px',

		[theme.breakpoints.up('md')]: {
			display: 'flex',
			gap: '10px',
		},
		[theme.breakpoints.up('xl')]: {
			display: 'flex',
			gap: '60px',
		},
	},

	link: {
		'&.MuiLink-root': {
			display: 'flex',
			alignItems: 'center',
			height: '50px',
			padding: '0px 10px',
			margin: '0',
			fontFamily: 'Lato, sans-serif',
			fontSize: '16px',
			color: theme.palette.primary.contrastText,
			textDecoration: 'none',
			position: 'relative',

			'&:after': {
				content: '\'\'',
				position: 'absolute',
				bottom: '0px',
				left: '50%',
				transform: 'translateX(-50%)',
				width: '0px',
				height: '3px',
				background: theme.palette.secondary.main,
				transition: 'width 400ms cubic-bezier(0.25, 0.8, 0.25, 1)',
			},

			'&:hover': {
				'&:after': {
					width: '100%',
				}
			},
		},
	}
}));
