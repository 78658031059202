import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) => ({
	root: {
		padding: '32px 0 20px',

		[theme.breakpoints.up('md')]: {
			padding: '80px 0 100px',
		}
	},
	content: {
		[theme.breakpoints.up('lg')]: {
			display: 'flex',
			gap: '20px',
			justifyContent: 'space-between',
		}
	},
	aboutInfo: {
		[theme.breakpoints.up('lg')]: {
			flexBasis: '538px',
		}
	},
	aboutInfoFixed: {
		position: 'sticky',
		top: '50px',
	},
	aboutTitle: {
		fontFamily: 'Montserrat Alternates, sans-serif',
		fontSize: '24px',
		fontWeight: 700,
		margin: '0 0 23px',
		textTransform: 'uppercase',

		[theme.breakpoints.up('md')]: {
			fontSize: '54px',
			margin: '0 0 52px',
		}
	},
	aboutText: {
		fontSize: '14px',
		lineHeight: '22px',
		opacity: 0.8,
		margin: '0 0 8px',

		[theme.breakpoints.up('md')]: {
			fontSize: '16px',
			lineHeight: '25px',
		}
	},
	aboutList: {
		padding: '0 0 20px',

		[theme.breakpoints.up('md')]: {
			padding: '17px 0 20px',
		}
	},
	aboutListItem: {
		position: 'relative',
		margin: '0 0 5px',
		padding: '0 0 0 22px',

		'&:before': {
			content: '\'\'',
			display: 'block',
			position: 'absolute',
			top: '9px',
			left: '5px',
			width: '6px',
			height: '6px',
			borderRadius: '3px',
			backgroundColor: theme.palette.primary.contrastText,

			[theme.breakpoints.up('md')]: {
				top: '11px',
			}
		}
	},
	aboutListText: {
		display: 'inline',
		fontSize: '14px',
		fontWeight: 700,

		[theme.breakpoints.up('md')]: {
			fontSize: '16px',
			lineHeight: '25px',
		}
	},
	aboutListSubText: {
		display: 'inline',
	},
	aboutImgWrapper: {
		display: 'flex',
		justifyContent: 'center',
	},
	aboutImg: {
		maxWidth: '100%',
		maxHeight: '100%',
	},

	details: {
		padding: '40px 0 0',

		[theme.breakpoints.up('lg')]: {
			padding: '130px 0 0',
			flexBasis: '878px',
		}
	},
	detailsBox: {
		borderRadius: '10px',
		background: 'rgba(66, 64, 65, 0.3)',
		backdropFilter: 'blur(45px)',
		padding: '20px',

		'&:not(:last-child)': {
			margin: '0 0 40px',
		},

		[theme.breakpoints.up('lg')]: {
			padding: '45px 80px 45px',

			'&:not(:last-child)': {
				margin: '0 0 80px',
			},
		}
	},
	detailsTitle: {
		fontFamily: 'Montserrat Alternates, sans-serif',
		fontSize: '20px',
		fontWeight: '700',
		textTransform: 'uppercase',

		[theme.breakpoints.up('lg')]: {
			fontSize: '40px',
			margin: '0 0 25px',
		}
	},
	detailsList: {
		padding: '10px 0 0',
	},
	detailsListItem: {
		padding: '15px 0',

		[theme.breakpoints.up('lg')]: {
			display: 'flex',
			alignItems: 'center',
			gap: '20px',
			padding: '30px 0',
		},

		'&:not(:last-child)': {
			borderBottom: '1px solid rgba(255, 255, 252, .6)',
		},
		'&:last-child': {
			paddingBottom: '0px',
		},
	},
	detailsSubTitle: {
		width: '237px',
		fontFamily: 'Montserrat Alternates, sans-serif',
		fontSize: '18px',
		fontWeight: 700,
		margin: '0 0 10px',

		[theme.breakpoints.up('md')]: {
			fontSize: '24px',
			lineHeight: 1.3,
		},
		[theme.breakpoints.up('lg')]: {
			flexBasis: '237px',
			flexShrink: 0,
		}
	},
	fullWidth: {
		width: '100%',
		[theme.breakpoints.up('lg')]: {
			width: '237px',
		}
	},
	detailsText: {
		fontSize: '12px',
		lineHeight: '20px',
		opacity: 0.8,
		margin: 0,

		[theme.breakpoints.up('md')]: {
			fontSize: '16px',
			lineHeight: '25px',
		}
	},
}));
