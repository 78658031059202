import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
// @ts-ignore
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

import { API_HOST } from '../constants';

export const apiClient = new ApolloClient({
	//@ts-ignore
	link: createUploadLink({
		uri: `${API_HOST}/graphql`,
		headers: { 'Apollo-Require-Preflight': 'true' },
	}),
	cache: new InMemoryCache(),
});

export const GET_LANDING_DATA = gql`
	query {
	  faqAll {
	    id
	    question
	    answer
	  }
	  testimonials {
	    id
	    name
	    description
	    photoUrl
	  }
	  pricePlans {
	    id
	    title
	    cost
	    orderId
	    benefits {
	      label
	      isActive
	      subList
	    }
	  }
	  courseModules {
	    id
	    title
	    topics
	  }
	}
`;

export const CREATE_REGISTRATION_REQUEST = gql`
	mutation createRegistration($data: CreateRegistrationRequestDto!) {
		createRegistrationRequest(createRegistrationRequestDto: $data) {
			id
			email
		}
	}
`;

export const GET_REGISTRATION_REQUEST = gql`
	query GetOfferById($id: Int!) {
		offer(id: $id) {
			id
			title
			unique_offer_code
		}
  	}
`;
