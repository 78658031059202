import React from 'react';
import { Typography } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import classNames from 'classnames';

import { PlanBenefit } from '../../../../../interfaces';
import useStyles from './styles';

interface Props {
	benefits: PlanBenefit[];
}

const BenefitsList: React.FC<Props> = ({ benefits }: Props) => {
	const classes = useStyles();
	return (
		<ul className={classes.benefitList}>
			{benefits.map(({ isActive, label, subList }) => (
				<li key={label} className={classes.benefitListItem}>
					<div className={classes.benefitItemSummary}>
						<CheckCircleRoundedIcon
							fontSize="small"
							className={classNames(classes.checkIcon, { inactive: !isActive })}
						/>
						<Typography className={classNames(classes.benefitItemLabel, { inactive: !isActive })}>
							{label}
						</Typography>
					</div>
					{!!subList.length && (
						<ul className={classes.subList}>
							{subList.map(item => (
								<li key={item} className={classes.subListItem}>
									<Typography className={classNames(classes.subListText, { inactive: !isActive })}>{item}</Typography>
								</li>
							))}
						</ul>
					)}
				</li>
			))}
		</ul>
	);
};

export default BenefitsList;
