import { createBrowserRouter, Navigate } from 'react-router-dom';
import PublicLayout from '../layouts/PublicLayout';
import Landing from '../pages/Landing';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import PersonalData from '../pages/PersonalData';
import Agreement from '../pages/Agreement';

export const routes = createBrowserRouter([
	{
		path: '/',
		element: <PublicLayout />,
		children: [
			{
				index: true,
				element: <Landing />
			},
			{
				path: 'privacy-policy',
				element: <PrivacyPolicy />,
			},
			{
				path: 'processing-of-personal-data',
				element: <PersonalData />,
			},
			{
				path: 'agreement',
				element: <Agreement />,
			},
		]
	},
	{
		path: 'admin',
		lazy: async () => await import('../layouts/AdminLayout'),
		children: [
			{
				path: '',
				lazy: async () => await import('../layouts/DashboardLayout'),
				children: [
					{
						index: true,
						lazy: async () => await import('../pages/admin/CourseProgram'),
					},
					{
						path: 'price',
						lazy: async () => await import('../pages/admin/PricePlans'),
					},
					{
						path: 'testimonials',
						lazy: async () => await import('../pages/admin/Testimonials'),
					},
					{
						path: 'faq',
						lazy: async () => await import('../pages/admin/Faq'),
					},
					{
						path: 'payment-requests',
						lazy: async () => await import('../pages/admin/PaymentRequests'),
					},
				],
			},
			{
				path: 'login',
				lazy: async () => await import('../pages/admin/Login'),
			},
			{
				path: '*',
				element: <Navigate replace to="/admin" />
			},
		],
	},
	{
		path: '*',
		element: <Navigate replace to="/" />
	},
]);
