import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles(({ breakpoints }: Theme) => ({
	root: {
		background: 'rgba(66, 64, 65, .3)',
		backdropFilter: 'blur(45px)',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px 0 40px',

		[breakpoints.up('md')]: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			padding: '80px 0',
		},
	},
	menuList: {
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
		maxWidth: '100%',
		flexWrap: 'wrap',
		textAlign: 'center',

		[breakpoints.up('xl')]: {
			width: '50%',
			gap: '20px 5%',
			flexDirection: 'row',
			justifyContent: 'space-between',
			textAlign: 'left',
		},
	},
	menuLink: {
		'&.MuiLink-root': {
			color: 'rgba(255, 255, 252, 0.60)',
			fontSize: '12px',

			[breakpoints.up('md')]: {
				fontSize: '16px',
			}
		}
	},
	socialList: {
		margin: '40px 0 30px',

		[breakpoints.up('md')]: {
			order: 3,
			margin: '0',
		}
	}
}));
