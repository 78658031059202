import React, { ChangeEvent, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
	Button,
	Checkbox,
	Container,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	IconButton,
	Link,
	MenuItem,
	TextField,
	Typography,
	useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';

import useCreateRegistration from '../../../../api/use-create-registration';
import { PricePlan } from '../../../../interfaces';
import PlanItem from './PlanItem';
import useStyles from './styles';
import useOfferData from '../../../../api/use-offer-data';

interface RegistrationForm {
	firstName: string;
	lastName: string;
	email: string;
	phone: string
	offerId?: number;
	privacyPolicyChecked?: boolean;
}

interface Props {
	pricePlans: PricePlan[];
}

const paymentBaseURL = 'https://wootitude-course.kwiga.com/o/';

const phoneRegExp = /^((\+[1-9]{1,4}[ -]*)|(\([0-9]{2,3}\)[ -]*)|([0-9]{2,4})[ -]*)*?[0-9]{3,4}?[ -]*[0-9]{3,4}?$/;
const validationSchema = Yup.object().shape({
	firstName: Yup.string().required(),
	lastName: Yup.string().required(),
	email: Yup.string().email().required(),
	phone: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid'),
	privacyPolicyChecked: Yup.boolean().oneOf([true], 'Field must be checked').required(),
});

const PricePlans: React.FC<Props> = ({ pricePlans }: Props) => {
	const classes = useStyles();
	const theme = useTheme<Theme>();
	const detailBtnHidden = useMediaQuery(theme.breakpoints.up('md'));
	const [selectedPlan, setSelectedPlan] = useState<PricePlan | null>(null);
	const [successModal, setSuccessModal] = useState(false);
	const getRegistrationRequest = useOfferData();

	const { create, loading, error } = useCreateRegistration();
	const { values, errors, touched, submitCount, handleSubmit, handleChange, resetForm } = useFormik<RegistrationForm>({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			privacyPolicyChecked: false,
		},
		validationSchema,
		onSubmit: async (value) => {
			if (!loading) {
				try {
					await create({
						firstName: value.firstName,
						lastName: value.lastName,
						email: value.email,
						phoneNumber: value.phone,
					});

					const { data } = await getRegistrationRequest({
						variables: {
							id: selectedPlan?.orderId as number,
						}
					});
					resetForm();
					setSelectedPlan(null);
					setSuccessModal(true);

					const offer = data?.offer;
					// @ts-ignore
					window?.open?.(
						`${paymentBaseURL}${offer.unique_offer_code}?email=${encodeURIComponent(value.email)}`,
						'_blank'
					).focus();
					
					if (offer) {
						// @ts-ignore
						window?.open?.(
							`${paymentBaseURL}${offer.unique_offer_code}?email=${encodeURIComponent(value.email)}`,
							'_blank'
						).focus();
					}
				} catch {
					resetForm();
				}
			}
		},
	});

	const handleSelectPlan = (e: ChangeEvent<HTMLInputElement>) => {
		const planId = e.target.value;
		setSelectedPlan(pricePlans.find(({ id }) => id === planId) as PricePlan);
	};

	return (
		<>
			<section id="price-plans" className={classes.root}>
				<Container maxWidth="xl">
					<Typography className={classes.sectionTitleText}>ВАРТІСТЬ НАВЧАННЯ</Typography>
					<ul className={classes.priceList}>
						{pricePlans.map((plan, i) => (
							<PlanItem
								key={plan.id}
								plan={plan}
								initialOpen={i === 0 || detailBtnHidden}
								detailBtnHidden={detailBtnHidden}
								selectPlan={setSelectedPlan}
							/>
						))}
					</ul>
				</Container>
			</section>
			<Dialog  open={!!selectedPlan} onClose={() => setSelectedPlan(null)}>
				<DialogTitle>
					Реєстрація на курс
					<IconButton className={classes.dialogCloseBtn} onClick={() => setSelectedPlan(null)}>
						<CloseRoundedIcon fontSize="large" />
					</IconButton>
				</DialogTitle>
				<form onSubmit={handleSubmit}>
					<DialogContent>
						<DialogContentText>
							Персональні дані
						</DialogContentText>
						<TextField
							fullWidth
							name="firstName"
							label="Ваше ім'я"
							variant="standard"
							value={values.firstName}
							error={!!(errors.firstName && touched.firstName)}
							helperText={errors.firstName && touched.firstName ? 'Ім\'я обов\'язкове поле' : undefined}
							onChange={handleChange}
						/>
						<TextField
							fullWidth
							name="lastName"
							label="Ваше прізвище"
							variant="standard"
							value={values.lastName}
							error={!!(errors.lastName && touched.lastName)}
							helperText={errors.lastName && touched.lastName ? 'Прізвище обов\'язкове поле' : undefined}
							onChange={handleChange}
						/>
						<TextField
							fullWidth
							name="phone"
							label="Ваш номер телефону"
							variant="standard"
							value={values.phone}
							error={!!(errors.phone && touched.phone)}
							helperText={errors.phone && touched.phone ? 'Формат телефону неправильний ' : undefined}
							onChange={handleChange}
						/>
						<TextField
							fullWidth
							name="email"
							label="Email"
							variant="standard"
							value={values.email}
							error={!!(errors.email && touched.email)}
							helperText={errors.email && touched.email ? 'Електронна адреса не валідна' : undefined}
							onChange={handleChange}
						/>
						<TextField
							select
							fullWidth
							label="Тариф"
							name="selectedPlan"
							variant="standard"
							SelectProps={{
								MenuProps: { className: classes.selectDropdown },
								IconComponent: (_props) => <KeyboardArrowDownRoundedIcon {..._props} />
							}}
							value={selectedPlan?.id || ''}
							onChange={handleSelectPlan}
						>
							{pricePlans.map(({ id, title }) => (
								<MenuItem key={id} value={id}>{title}</MenuItem>
							))}
						</TextField>
						<FormControlLabel
							control={
								<Checkbox
									name="privacyPolicyChecked"
									checked={values.privacyPolicyChecked}
									onChange={handleChange}
								/>
							}
							className={classes.privacyField}
							componentsProps={{
								typography: {
									className: classNames(classes.privacyLabel, {
										error: errors.privacyPolicyChecked && touched.privacyPolicyChecked && submitCount > 0
									})
								}
							}}
							label={(
								<>
									Я погоджуюсь з
									{' '}
									<Link to="/agreement" target="_blank" component={RouterLink} className={classes.textLink}>
										умовами надання послуг
									</Link>
								</>
							)}
						/>
					</DialogContent>
					<DialogActions>
						<div className={classes.dialogActionsWrapper}>
							{loading ? (
								<Button type="button" variant="contained">
									<CircularProgress />
								</Button>
							) : (
								<Button type="submit" variant="contained">Оплатити</Button>
							)}
							{!!error && (
								<Typography className={classes.errorMessage}>
									Щось пішло не так. Будь ласка спробуйте ще раз.
								</Typography>
							)}
						</div>
					</DialogActions>
				</form>
			</Dialog>
			<Dialog  open={successModal} onClose={() => setSuccessModal(false)}>
				<DialogTitle>Дякуємо за реєстрацію</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<IconButton className={classes.dialogCloseBtn} onClick={() => setSuccessModal(false)}>
							<CloseRoundedIcon fontSize="large" />
						</IconButton>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button type="button" variant="contained" onClick={() => setSuccessModal(false)}>Закрити</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default PricePlans;
