import React from 'react';
import {Container, Typography} from '@mui/material';
import classNames from 'classnames';

import useStyles from './styles';

const mentors = [
	{
		fullName: 'Яніна Мясникова',
		position: 'CEO of Recruitment Agency - Wootitude/Head of Recruitment Department at Hygge Software',
		description: 'Більше 14 років в рекрутменті, з них 8 років в ІТ рекрутменті. Більше 5 років досвіду викладання та управління командами рекрутерів. Досвід роботи в стартапах, запуск відділу з нуля.',
		photoSrc: '/assets/mentor1.1.jpg',
	},
	{
		fullName: 'Аліна Сухова',
		position: 'Co-founder of Recruitment Agency - Wootitude/ CEO at Hygge Software',
		description: 'Операційна директорка (CEO) IT компанії Hygge Software, cпіввласниця Wootitude. У минулому - Front-End розробниця. Наразі більше 4 років керує компанією та відповідає за будування та підтримку операційної діяльності.',
		photoSrc: '/assets/mentor2.2.jpg',
	},
];

const Mentors: React.FC = () => {
	const classes = useStyles();
	return (
		<section className={classes.root}>
			<Container maxWidth="xl">
				<Typography className={classes.sectionTitleText}>Менторки</Typography>
				<ul className={classes.mentorsList}>
					{mentors.map(({ fullName, position, description, photoSrc }) => (
						<li key={fullName} className={classes.mentorsListItem}>
							<div className={classes.mentorImgWrapper}>
								<img src={photoSrc} alt={fullName} className={classes.mentorImage}/>
							</div>
							<Typography className={classes.mentorFullName}>{fullName}</Typography>
							<Typography className={classNames(classes.mentorPosition, classes.mentorText)}>{position}</Typography>
							<Typography className={classes.mentorText}>{description}</Typography>
						</li>
					))}
				</ul>
			</Container>
		</section>
	);
};

export default Mentors;
