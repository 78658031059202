import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) => ({
	root: {
		padding: '32px 0 20px',

		[theme.breakpoints.up('md')]: {
			padding: '80px 0 100px',
		}
	},
	content: {
		[theme.breakpoints.up('lg')]: {
			display: 'flex',
			gap: '20px',
			justifyContent: 'space-between',
		}
	},
	sectionTitle: {
		padding: '0 0 32px',

		[theme.breakpoints.up('lg')]: {
			flexBasis: '538px',
		},
	},
	sectionTitleText: {
		fontFamily: 'Montserrat Alternates, sans-serif',
		fontSize: '24px',
		fontWeight: 700,
		textTransform: 'uppercase',

		[theme.breakpoints.up('md')]: {
			fontSize: '54px',
			position: 'sticky',
			top: '50px',
		}
	},
	courseProgram: {
		[theme.breakpoints.up('lg')]: {
			flexBasis: '878px',
		},
	},
	programList: {
		padding: '0 0 30px',

		[theme.breakpoints.up('md')]: {
			padding: '0 0 50px',
		},
	},

	programItemNumber: {
		color: '#EBFF33',
		fontFamily: 'Montserrat Alternates, sans-serif',
		fontSize: '18px',
		fontWeight: 600,
		marginBottom: '8px',

		[theme.breakpoints.up('md')]: {
			fontSize: '20px',
			marginBottom: '12px',
		},
	},
	programItemTitle: {
		fontFamily: 'Montserrat Alternates, sans-serif',
		fontSize: '20px',
		fontWeight: 700,

		[theme.breakpoints.up('md')]: {
			fontSize: '24px',
		},
	},
	programSubList: {
		// padding: '8px 0 0',

		[theme.breakpoints.up('md')]: {
			// padding: '18px 0',
		},
	},
	programSubListItem: {
		position: 'relative',
		padding: '0 0 0 15px',

		'&:not(:last-child)': {
			margin: '0 0 4px',

			[theme.breakpoints.up('md')]: {
				margin: '0 0 8px',
			},
		},
		'&:before': {
			content: '\'—\'',
			display: 'inline',
			position: 'absolute',
			top: 0,
			left: 0,
			fontSize: '12px',
			lineHeight: '19px',
			color: theme.palette.secondary.contrastText,
			opacity: .8,

			[theme.breakpoints.up('md')]: {
				fontSize: '16px',
				lineHeight: '25px',
			},
		}
	},
	programSubListText: {
		fontSize: '12px',
		lineHeight: '19px',
		opacity: .8,

		[theme.breakpoints.up('md')]: {
			fontSize: '16px',
			lineHeight: '25px',
		},
	},
}));
