import palette from '../palette';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	styleOverrides: {
		root: ({ theme }: any) => ({

			'& .MuiPaper-root': {
				background: '#211F20',
				borderRadius: '20px',
				width: '545px',
			},
			'& .MuiDialogTitle-root': {
				color: palette.primary?.contrastText,
				textAlign: 'center',
				padding: '60px 20px 8px',

				[theme.breakpoints.up('md')]: {
					padding: '60px 100px 8px',
				},
			},
			'& .MuiDialogContent-root': {
				padding: '0px 20px 20px',

				[theme.breakpoints.up('md')]: {
					padding: '0px 100px 50px',
				},
			},
			'& .MuiDialogContentText-root': {
				color: palette.primary?.contrastText,
				textAlign: 'center',
				opacity: .8,
				padding: '0 0 14px',
			},
			'& .MuiDialogActions-root': {
				padding: '0px 20px 30px',
				justifyContent: 'center',

				[theme.breakpoints.up('md')]: {
					padding: '0px 100px 60px',
				},
			},

			'& .DialogCloseBtn': {
				position: 'absolute',
				top: '0px',
				right: '0px',
				color: theme.palette.primary.contrastText,
			},
		}),
	},
};
