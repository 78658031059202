import React from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: theme.palette.primary.main,
		minHeight: '100vh',
		overflow: 'clip',
		position: 'relative',
	},
	bgLight: {
		display: 'none',
		position: 'absolute',
		left: '50%',
		width: '1000px',
		height: '1000px',
		borderRadius: '100%',
		pointerEvents: 'none',
		background: 'radial-gradient(rgba(0,0,255,0.25), transparent 70%)',
		zIndex: 0,

		[theme.breakpoints.up('md')]: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	},
}));

const PublicLayout: React.FC = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.bgLight} style={{ top: '-3%', transform: 'translateX(32%)' }} />
			<div className={classes.bgLight} style={{ top: '4%', transform: 'translateX(-135%)' }} />
			<div className={classes.bgLight} style={{ top: '17%', transform: 'translateX(50%)' }} />
			<div className={classes.bgLight} style={{ top: '35%', transform: 'translateX(-130%)' }} />
			<div className={classes.bgLight} style={{ top: '44%', transform: 'translateX(45%)' }} />
			<div className={classes.bgLight} style={{ top: '53%', transform: 'translateX(-130%)' }} />
			<div className={classes.bgLight} style={{ top: '60%', transform: 'translateX(11%)' }} />
			<div className={classes.bgLight} style={{ top: '79%', transform: 'translateX(-134%)' }} />
			<div className={classes.bgLight} style={{ top: '90%', transform: 'translateX(48%)' }} />
			<Header/>
			<div style={{ position: 'relative', zIndex: 1 }}>
				<Outlet/>
			</div>
			<Footer/>
		</div>
	)
};

export default PublicLayout;
