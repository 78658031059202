import { useMutation } from '@apollo/client';
import { CREATE_REGISTRATION_REQUEST } from './api-client';

export interface CreateRegistrationRequest {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string
}

const useCreateRegistration = () => {
	const [create, { data, loading, error }] = useMutation(CREATE_REGISTRATION_REQUEST);

	return {
		data,
		loading,
		error,
		create: (payload: CreateRegistrationRequest) => create({ variables: { data: payload }}),
	};
};

export default useCreateRegistration;
