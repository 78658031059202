import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) => ({
	priceListItem: {
		maxWidth: '100%',
		flexBasis: '100%',
		flexShrink: 1,
		borderRadius: '10px',
		backdropFilter: 'blur(45px)',
		background: 'rgba(66, 64, 65, .3)',
		border: '1px solid transparent',
		padding: '20px 20px 20px',

		[theme.breakpoints.up('md')]: {
			flexBasis: '48%',
			margin: '0 0 32px',
		},
		[theme.breakpoints.up('lg')]: {
			flexBasis: '350px',
			margin: 0,

			'&:hover': {
				border: '1px solid #EBFF33',
			},
		},

		'&:not(:last-child)': {
			margin: '0 0 32px',

			[theme.breakpoints.up('lg')]: {
				margin: 0,
			}
		},
	},
	priceItemTitle: {
		fontFamily: 'Montserrat Alternates, sans-serif',
		fontSize: '24px',
		fontWeight: 600,
		color: '#EBFF33',
	},
	priceItemPrice: {
		display: 'flex',
		alignItems: 'center',
		padding: '8px 0',
	},
	priceItemPriceCurrency: {
		color: '#EBFF33',
		margin: '-8px -4px 0 0',
	},
	priceItemPriceNumber: {
		fontFamily: 'Montserrat Alternates, sans-serif',
		fontSize: '48px',
		fontWeight: 700,
	},
	selectBtn: {
		width: '100%',
		maxWidth: '100%',
		minWidth: 'auto'
	},
	showDetailsBtn: {
		margin: '10px auto 0',
		display: 'flex',

		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	}
}));
