import palette from '../palette';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	styleOverrides: {
		root: ({ theme }: any) => ({
			minWidth: '250px',
			maxWidth: '100%',
			width: '100%',
			height: '52px',
			borderRadius: '10px',

			[theme.breakpoints.up('md')]: {
				maxWidth: '250px',
			},

			'&.MuiButton-sizeSmall': {
				padding: '10px',
				fontSize: '12px',
				textTransform: 'none',
				width: 'auto',
				minWidth: 'auto',
				height: 'auto',
			},

			'& .MuiCircularProgress-root': {
				width: '30px!important',
				height: '30px!important',
			},
		}),
		contained: {
			fontFamily: 'Lato, sans-serif',
			fontSize: '16px',
			fontWeight: 700,
			textTransform: 'none' as const,

			'&:not(.MuiButton-containedError)': {
				color: palette.secondary?.contrastText,
				backgroundColor: palette.secondary?.main,
			},

			'&.Mui-disabled:not(.MuiButton-containedError)': {
				backgroundColor: '#646262',
				boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.15)',
				color: '#211F20',
			},
			'&:not(.MuiButton-containedError):hover ': {
				backgroundColor: '#3232FE',
				boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.15)',
			},

			'& .MuiCircularProgress-root': {
				color: palette.secondary?.contrastText,
			},
		},
		text: {
			color: 'rgba(255, 255, 252, 0.60);',
		},
	},
};
