import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) => ({
	root: {
		padding: '32px 0 48px',

		[theme.breakpoints.up('md')]: {
			padding: '80px 0 68px',
		},
		[theme.breakpoints.up('lg')]: {
			padding: '80px 0 180px',
		},
	},
	sectionTitleText: {
		fontFamily: 'Montserrat Alternates, sans-serif',
		fontSize: '24px',
		fontWeight: 700,
		textTransform: 'uppercase',
		padding: '0 0 32px',

		[theme.breakpoints.up('md')]: {
			fontSize: '54px',
			padding: '0 0 64px',
		},
	},
	subTitle1: {
		fontSize: '16px',
		fontWeight: '700',
		lineHeight: 'normal',
		padding: '10px 0 10px',

		[theme.breakpoints.up('md')]: {
			fontSize: '18px',
			padding: '12px 0 18px',
		},
	},
	textDefault: {
		opacity: .8,
		fontSize: '14px',
		lineHeight: '22px',
		margin: '0 0 8px',

		[theme.breakpoints.up('md')]: {
			fontSize: '16px',
			lineHeight: '25px',
			margin: '0 0 12px',
		}
	},
	list: {
		padding: '0 0 8px',

		[theme.breakpoints.up('md')]: {
			padding: '0px 0 12px',
		}
	},
	listItem: {
		position: 'relative',
		margin: '0 0 5px',
		padding: '0 0 0 22px',

		'&:before': {
			content: '\'\'',
			display: 'block',
			position: 'absolute',
			top: '9px',
			left: '5px',
			width: '6px',
			height: '6px',
			borderRadius: '3px',
			backgroundColor: theme.palette.primary.contrastText,

			[theme.breakpoints.up('md')]: {
				top: '11px',
			}
		}
	},
	listItemHeadLine: {
		display: 'inline',
		fontSize: '14px',
		fontWeight: 700,

		[theme.breakpoints.up('md')]: {
			fontSize: '16px',
			lineHeight: '25px',
		}
	},
	listItemSubText: {
		display: 'inline',
	},
	block: {
		padding: '0px 0 8px',

		[theme.breakpoints.up('md')]: {
			padding: '0px 0 12px',
		},
	},
	emailLink: {
		color: '#FFFFFC',
		fontSize: '16px',
		fontWeight: 700,
		lineHeight: '160%',
		textDecoration: 'underline',
	},
	link: {
		color: 'inherit',
		textDecoration: 'underline',
	},
}));
