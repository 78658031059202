import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { ApolloProvider } from '@apollo/client';

import { apiClient } from './api/api-client';
import theme from './theme';
import { routes } from './routes';

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

function App() {
  return (
    <ApolloProvider client={apiClient}>
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RouterProvider router={routes} />
        </ThemeProvider>
      </CacheProvider>
    </ApolloProvider>
  );
}

export default App;
