import React from 'react';
import { Container, Link, Typography } from '@mui/material';
import classNames from 'classnames';

import useStyles from './styles';

const PrivacyPolicy: React.FC = () => {
	const classes = useStyles();
	return (
		<section id="price-plans" className={classes.root}>
			<Container maxWidth="lg">
				<Typography className={classes.sectionTitleText}>Політика конфіденційності</Typography>

				<Typography className={classes.textDefault}>
					Компанія Wootitude (далі - "Ми", "Наша компанія") прагне забезпечити конфіденційність та захист особистих даних наших користувачів. Наша політика конфіденційності описує, як ми збираємо, використовуємо, розкриваємо, передаємо та зберігаємо інформацію, яку надають наші користувачі нашому веб-сайту та відповідних послугах.
				</Typography>
				<Typography className={classes.subTitle1}>
					Збір та використання інформації
				</Typography>
				<Typography className={classes.textDefault}>
					Ми можемо збирати різні види інформації для різних цілей з метою надання та покращення наших послуг. Ваші особисті дані можуть включати, але не обмежуватися, наступними:
				</Typography>

				<ul className={classes.list}>
					<li className={classes.listItem}>
						<Typography className={classes.listItemHeadLine}>
							Особиста інформація:
						</Typography>
						{' '}
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							В рамках користування нашими послугами, ви можете надавати особисті дані, такі як ім'я, електронну пошту та контактну інформацію.
						</Typography>
					</li>
					<li className={classes.listItem}>
						<Typography className={classes.listItemHeadLine}>
							Інформація про використання:
						</Typography>
						{' '}
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							Ми автоматично збираємо інформацію про те, як ви використовуєте наш веб-сайт та послуги, включаючи дані про ваші запити, діяльність на сайті та іншу інформацію.
						</Typography>
					</li>
					<li className={classes.listItem}>
						<Typography className={classes.listItemHeadLine}>
							Інформація з файлів журналів:
						</Typography>
						{' '}
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							Ми можемо збирати інформацію з файлів журналів сервера, таку як IP-адреси, запити браузера, дата та час ваших відвідувань.
						</Typography>
					</li>
				</ul>
				<Typography className={classes.subTitle1}>
					Використання інформації
				</Typography>
				<Typography className={classes.textDefault}>
					Ми використовуємо інформацію, яку ми збираємо, для наступних цілей:
				</Typography>

				<ul className={classes.list}>
					<li className={classes.listItem}>
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							З вашого дозволу: Ми можемо розкривати вашу інформацію за вашою згодою.
						</Typography>
					</li>
					<li className={classes.listItem}>
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							З метою виконання обов'язків: Ми можемо розкривати інформацію для виконання наших обов'язків та надання послуг.
						</Typography>
					</li>
					<li className={classes.listItem}>
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							З метою захисту прав та безпеки: Ми можемо розкривати інформацію для захисту прав, власності або безпеки нашої компанії, користувачів та інших осіб.
						</Typography>
					</li>
					<li className={classes.listItem}>
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							Законодавство та дотримання правил: Ми можемо розкривати інформацію відповідно до законодавства та вимог регуляторів.
						</Typography>
					</li>
				</ul>
				<Typography className={classes.subTitle1}>
					Зберігання інформації
				</Typography>
				<Typography className={classes.textDefault}>
					Ми зберігаємо інформацію лише стільки часу, скільки вона необхідна для досягнення цілей, зазначених у цій політиці конфіденційності, якщо інше не вимагає законодавство.
				</Typography>

				<Typography className={classes.subTitle1}>
					Зміни в політиці конфіденційності
				</Typography>
				<Typography className={classes.textDefault}>
					Ми можемо час від часу оновлювати цю Політику конфіденційності. Будь ласка, періодично переглядайте цю сторінку, щоб бути в курсі будь-яких змін. Продовжуючи використовувати наш веб-сайт після внесення змін до цієї Політики конфіденційності, ви підтверджуєте свою згоду з такими змінами.
				</Typography>

				<Typography className={classes.subTitle1}>
					Зв'яжіться з нами
				</Typography>
				<div className={classes.block}>
					<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
						Якщо у вас є будь-які питання щодо цієї політики конфіденційності або її застосування, будь ласка, зв'яжіться з нами за адресою
					</Typography>
					{' '}
					<Link href="mailto:lets.talk@wootitude.com" className={classes.emailLink}>lets.talk@wootitude.com</Link>
				</div>

				<Typography className={classes.subTitle1}>
					Ця Політика конфіденційності набуває чинності з дати її публікації.
				</Typography>
			</Container>
		</section>
	);
};

export default PrivacyPolicy;
