import React, {PropsWithChildren} from 'react';
import {Container, IconButton, Typography} from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import Slider from 'react-slick';
import classNames from 'classnames';

import { API_STATICS_PATH } from '../../../../constants';
import {Testimonial} from '../../../../interfaces';
import useStyles from './styles';

interface SlickControlBtnProps {
	icon?: React.JSX.Element;
	className?: string;
	style?: any;
	onClick?(): void;
}

interface Props {
	testimonials: Testimonial[];
}

const Testimonials: React.FC<Props> = ({ testimonials }: Props) => {
	const classes = useStyles();

	const SlickControlBtn = ({ icon, className, onClick }: PropsWithChildren<SlickControlBtnProps>) => (
		<IconButton
			disabled={className?.includes('slick-disabled')}
			className={classNames(className, classes.arrowBtn)}
			onClick={onClick}
		>
			{icon}
		</IconButton>
	);

	const slickSettings = {
		dots: true,
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		initialSlide: 0,
		mobileFirst: true,
		customPaging: () => (<button></button>),
		nextArrow: <SlickControlBtn icon={<ArrowForwardRoundedIcon />} />,
		prevArrow: <SlickControlBtn icon={<ArrowBackRoundedIcon />} />,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 899,
				settings: {
					centerMode: true,
					infinite: true,
					slidesToShow: 1,
					dots: false,
					arrows: false,
				},
			},
		],
	};

	return (
		<section id="testimonials" className={classes.root}>
			<Container maxWidth="xl">
				<Typography className={classes.sectionTitleText}>ЩО КАЖУТЬ ВИПУСКНИКИ КУРСУ?</Typography>
				<div className={classes.testimonialsList}>
					{!!testimonials.length && (
						<Slider {...slickSettings}>
							{testimonials.map(({ id, name, description, photoUrl }) => (
								<div key={id} className={classNames(classes.testimonialsItem, 'testimonialsSlide')}>
									<div className={classes.testimonialsItemHead}>
										<div className={classes.testimonialsItemImgBox}>
											<img src={`${API_STATICS_PATH}${photoUrl}`} alt="User" className={classes.testimonialsItemImage}/>
										</div>
										<Typography className={classes.testimonialsItemName}>{name}</Typography>
									</div>
									<Typography className={classes.testimonialsItemDescription}>"{description}"</Typography>
								</div>
							))}
						</Slider>
					)}
				</div>
			</Container>
		</section>
	);
};

export default Testimonials;