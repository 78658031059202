import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) => ({
	root: {
		padding: '32px 0 20px',

		[theme.breakpoints.up('md')]: {
			padding: '80px 0 100px',
		}
	},
	sectionTitleText: {
		fontFamily: 'Montserrat Alternates, sans-serif',
		fontSize: '24px',
		fontWeight: 700,
		textTransform: 'uppercase',

		[theme.breakpoints.up('md')]: {
			fontSize: '54px',
		}
	},
	mentorsList: {
		padding: '32px 0 0',

		[theme.breakpoints.up('sm')]: {
			display: 'flex',
			gap: '20px',
		},
		[theme.breakpoints.up('md')]: {
			gap: '40px',
			padding: '64px 0 0',
		},
	},
	mentorsListItem: {
		maxWidth: '100%',
		flexBasis: '350px',
		flexShrink: 1,

		'&:not(:last-child)': {
			margin: '0 0 32px',
		},
	},
	mentorImgWrapper: {},
	mentorImage: {
		maxWidth: '100%',
		maxHeight: '100%',
		aspectRatio: 35/48,
		objectFit: 'cover',
		objectPosition: 'top',
	},
	mentorFullName: {
		color: '#fff',
		fontFamily: 'Montserrat Alternates, sans-serif',
		fontSize: '20px',
		fontWeight: 700,
		margin: '8px 0 8px',

		[theme.breakpoints.up('md')]: {
			fontSize: '24px',
			margin: '16px 0 14px',
		}
	},
	mentorPosition: {
		margin: '0 0 4px',

		[theme.breakpoints.up('md')]: {
			margin: '0 0 10px',
		}
	},
	mentorText: {
		fontSize: '12px',
		fontWeight: 400,
		lineHeight: '20px',
		opacity: .8,

		[theme.breakpoints.up('md')]: {
			fontSize: '16px',
			lineHeight: '20px',
		}
	},
}));
