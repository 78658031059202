import { darken, Palette } from '@mui/material';

const palette: Partial<Palette> = {
	primary: {
		main: '#131112',
		light: '#131112',
		dark: '#131112',
		contrastText: '#FFFFFC'
	},
	secondary: {
		main: '#0000FF',
		light: '#0000FF',
		dark: '#0000FF',
		contrastText: '#FFFFFC'
	},
	background: {
		default: '#211F20',
		paper: darken('#211F20', .1),
	},
	tonalOffset: 0.5,
};

export default palette;
