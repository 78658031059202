import { GET_REGISTRATION_REQUEST } from './api-client';
import { ApolloError, LazyQueryExecFunction, OperationVariables, useLazyQuery } from '@apollo/client';

export interface State {
	data: any;
	loading: boolean;
	error: ApolloError | undefined;
}

const useOfferData = ():LazyQueryExecFunction<any, OperationVariables> => {
	const [getRegistrationRequest] = useLazyQuery(GET_REGISTRATION_REQUEST);

    return getRegistrationRequest;
};

export default useOfferData;
