import React from 'react';

import useLandingData from '../../api/use-landing-data';
import FirstScreen from './components/FirstScreen';
import About from './components/About';
import CourseProgram from './components/CourseProgram';
import Mentors from './components/Mentors';
import PricePlans from './components/PricePlans';
import Testimonials from './components/Testimonials';
import Faq from './components/Faq';

const Landing: React.FC = () => {
	const { data: { courseProgram, pricePlans, faqList, testimonials } } = useLandingData();

	return (
		<>
			<FirstScreen />
			<About />
			<CourseProgram program={courseProgram} />
			<Mentors />
			<PricePlans pricePlans={pricePlans} />
			<Testimonials testimonials={testimonials}/>
			<Faq faqList={faqList} />
		</>
	);
};

export default Landing;
