import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Link } from '@mui/material';

import SocialIconsList from '../SocialIconsList';
import Logo from '../Logo';
import useStyles from './styles';

const Footer = () => {
	const classes = useStyles();

	return (
		<footer className={classes.root}>
			<Container maxWidth="xl">
				<div className={classes.content}>
					<Logo />
					<div className={classes.socialList}>
						<SocialIconsList/>
					</div>
					<ul className={classes.menuList}>
						<li>
							<Link
								to="/privacy-policy"
								component={RouterLink}
								className={classes.menuLink}
							>
								Політика конфіденційності
							</Link>
						</li>
						<li>
							<Link
								to="/processing-of-personal-data"
								component={RouterLink}
								className={classes.menuLink}
							>
								Обробка персональних даних
							</Link>
						</li>
						<li>
							<Link
								to="/agreement"
								component={RouterLink}
								className={classes.menuLink}
							>
								Договір публічної оферти
							</Link>
						</li>
					</ul>
				</div>
			</Container>
		</footer>
	)
};

export default Footer;