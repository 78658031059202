import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default makeStyles((theme: Theme) => ({
	root: {
		padding: '32px 0 20px',

		[theme.breakpoints.up('md')]: {
			padding: '80px 0 100px',
		},
	},
	sectionTitleText: {
		fontFamily: 'Montserrat Alternates, sans-serif',
		fontSize: '24px',
		fontWeight: 700,
		textTransform: 'uppercase',

		[theme.breakpoints.up('md')]: {
			fontSize: '54px',
		},
	},
	testimonialsList: {
		padding: '32px 0 0',

		[theme.breakpoints.up('md')]: {
			padding: '64px 0 0',
		},

		'& .slick-list': {
			margin: '0 -10px',
			overflow: 'visible',

			[theme.breakpoints.up('md')]: {
				margin: '0 -20px 0 ',
			},
		},
		'& .slick-track': {
			display: 'flex',
			alignItems: 'stretch',
		},
		'& .slick-slide': {
			padding: '0 10px 0',
			height: 'auto',
			display: 'flex',
			alignItems: 'stretch',

			[theme.breakpoints.up('md')]: {
				padding: '0 20px 0 ',
			},
		},

		'& .slick-arrow': {
			top: '99%',
			bottom: 'auto',

			'&:before': {
				content: 'none',
			},
			'&.slick-prev': {
				left: 'auto',
				right: '60px',
			},
			'&.slick-next': {
				right: '0px'
			},
		},
		'& .slick-dots': {
			position: 'static',
			display: 'flex!important',
			padding: '55px 0 0',

			'& > li': {
				width: 'auto',
				height: 'auto',

				'& > button': {
					width: '10px',
					height: '10px',
					borderRadius: '10px',
					background: 'rgba(255, 255, 252, 0.60)',

					'&:before': {
						display: 'none',
					}
				},
				'&.slick-active': {
					'& > button': {
						width: '30px',
						background: theme.palette.secondary.main,
					},
				}
			},
		},
	},
	arrowBtn: {
		width: '40px',
		height: '40px',
		background: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText,

		'&:focus': {
			background: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
		},
		'&:hover': {
			background: '#3232FE',
			color: theme.palette.secondary.contrastText,
		},
		'&.Mui-disabled, &.slick-disabled, &.slick-disabled:focus': {
			color: theme.palette.secondary.contrastText,
			opacity: .6,
			background: 'transparent',
			border: `2px solid ${theme.palette.secondary.contrastText}`
		},
	},
	testimonialsItem: {
		borderRadius: '10px',
		background: 'rgba(66, 64, 65, 0.30)',
		backdropFilter: 'blur(45px)',
		padding: '10px 10px 6px',
		height: '100%',

		[theme.breakpoints.up('md')]: {
			padding: '30px 30px 25px',
			border: '2px solid transparent',

			'&:hover': {
				border: '2px solid #EBFF33',
			}
		},
	},
	testimonialsItemHead: {
		display: 'flex',
		alignItems: 'center',
		padding: '0 0 4px',

		[theme.breakpoints.up('md')]: {
			padding: '0 0 12px',
		},
	},
	testimonialsItemImgBox: {
		width: '37px',
		height: '37px',
		margin: '0 10px 0 0',
		borderRadius: '100%',
		overflow: 'hidden',

		[theme.breakpoints.up('md')]: {
			width: '50px',
			height: '50px',
			margin: '0 20px 0 0',
		},
	},
	testimonialsItemImage: {
		maxWidth: '100%',
		maxHeight: '100%',
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	testimonialsItemName: {
		fontSize: '14px',
		fontWeight: 700,

		[theme.breakpoints.up('md')]: {
			fontSize: '18px',
			fontWeight: 700,
		},
	},
	testimonialsItemDescription: {
		fontSize: '12px',
		lineHeight: '19px',
		fontWeight: 500,

		[theme.breakpoints.up('md')]: {
			fontSize: '16px',
			lineHeight: '25px',
		},
	},
}));
