import React from 'react';
import {Container, Typography} from '@mui/material';

import useStyles from './styles';
import classNames from "classnames";

const About: React.FC = () => {
	const classes = useStyles();

	return (
		<section id="about" className={classes.root}>
			<Container maxWidth="xl">
				<div className={classes.content}>
					<div className={classes.aboutInfo}>
						<div className={classes.aboutInfoFixed}>
							<Typography className={classes.aboutTitle}>Про наш курс</Typography>
							<Typography className={classes.aboutText}>
								<strong>ІТ рекрутер</strong> - це твій ключ до успішної кар'єри в цій швидкозмінній та високотехнологічній галузі. Ти отримаєш затребуваний skill set, згідно трендам галузі. Опануєш найсучасніші інструменти, перевірені провідними експертами, та станеш фахівцем, про якого мріють ТОП ІТ компанії.
							</Typography>
							<Typography className={classes.aboutText}>Чим відрізняється наш курс:</Typography>
							<ul className={classes.aboutList}>
								<li className={classes.aboutListItem}>
									<Typography className={classNames(classes.aboutText, classes.aboutListSubText)}>
										Можливість працювати під час навчання з реальними вакансіями та отримати бонуси за їх закриття.
									</Typography>
								</li>
								<li className={classes.aboutListItem}>
									<Typography className={classNames(classes.aboutText, classes.aboutListSubText)}>
										Матимеш змогу закрити позиції, що відкриті в нашій агенції та отримати свій перший бонус, як рекрутер.
									</Typography>
								</li>
								<li className={classes.aboutListItem}>
									<Typography className={classNames(classes.aboutText, classes.aboutListSubText)}>
										По завершенню курсу ти отримаєш не просто сертифікат, а резюме з підтвердженим досвідом роботи.
									</Typography>
								</li>
								<li className={classes.aboutListItem}>
									<Typography className={classNames(classes.aboutText, classes.aboutListSubText)}>
										Отримаєш можливість приєднатися до нашої команди.
									</Typography>
								</li>
								<li className={classes.aboutListItem}>
									<Typography className={classNames(classes.aboutText, classes.aboutListSubText)}>
										Ми не залишимо тебе самого навіть після завершення навчання, ми підтримуємо своїх студентів та допомагаємо з побудовою професійних зв'язків у галузі.
									</Typography>
								</li>
							</ul>
							<div className={classes.aboutImgWrapper}>
								<img src="/assets/about-screen-image.svg" alt="" className={classes.aboutImg}/>
							</div>
						</div>
					</div>

					<div className={classes.details}>
						<div className={classes.detailsBox}>
							<Typography className={classes.detailsTitle}>Для кого цей курс</Typography>
							<ul className={classes.detailsList}>
								<li className={classes.detailsListItem}>
									<Typography className={classes.detailsSubTitle}>Люди із різних галузей</Typography>
									<Typography className={classes.detailsText}>
										Ти вже - цінний і затребуваний! Ти маєш безліч навичок, ти мотивований. Ми допоможемо тобі використати твої сильні сторони і стати ТОП-фахівцем, отримати сучасну професію і працювати будь-де і самостійно керувати своїм часом.
									</Typography>
								</li>
								<li className={classes.detailsListItem}>
									<Typography className={classes.detailsSubTitle}>
										Працівники відділу кадрів
									</Typography>
									<Typography className={classes.detailsText}>
										HR-фахівці, які відповідають за рекрутинг в різних компаніях, зможуть світчнутись на затребувану позицію саме в ІТ, підвищіти свій дохід та бути незалежними від коливань ринку.
									</Typography>
								</li>
								<li className={classes.detailsListItem}>
									<Typography className={classes.detailsSubTitle}>
										Молоді рекрутери
									</Typography>
									<Typography className={classes.detailsText}>
										Ти вже робив спроби в цьому напрямку, але не маєш потрібного бекграунду. Хочеш стати ТОП-фахівцем, але не розумієш куди рухатися. Ми домомагаємо налаштувати роботу так, щоб мати реальний результат і закривати високооплачувані позиції.
									</Typography>
								</li>
								<li className={classes.detailsListItem}>
									<Typography className={classes.detailsSubTitle}>
										Випускники навчальних закладів
									</Typography>
									<Typography className={classes.detailsText}>
										На початку своєї кар’єри ти зрозумієш сферу IT та суттєво розшириш світогляд. Рекрутер має уникальну позицію - працюючи в одній сфері пізнає безліч інших.
									</Typography>
								</li>
								<li className={classes.detailsListItem}>
									<Typography className={classes.detailsSubTitle}>Матері, які виховують дітей</Typography>
									<Typography className={classes.detailsText}>
										Чому всі хочуть в ІТ? Бо тут є work/life balance. Отримай професію з гнучким графіком - заробляй в $$$ та не жертвуй сім’єю та власним життям.
									</Typography>
								</li>
							</ul>
						</div>

						<div className={classes.detailsBox}>
							<Typography className={classes.detailsTitle}>ЯК ПРОХОДИТЬ НАВЧАННЯ НА КУРСІ</Typography>
							<ul className={classes.detailsList}>
								<li className={classes.detailsListItem}>
									<Typography className={classes.detailsSubTitle}>Навчайся у будь-який час</Typography>
									<Typography className={classes.detailsText}>
										Ми врахували кращі практики тайм-менеджменту і розробили для тебе можливість структурувати своє навчання найкомфортнішим чином. На нашій платформі ти знайдеш <strong>короткі</strong> відео-уроки, які ти можеш дивитися в зручний для тебе час та скомбінувати з будь-яким графіком.
									</Typography>
								</li>
								<li className={classes.detailsListItem}>
									<Typography className={classes.detailsSubTitle}>
										Закріплюй знання на практиці
									</Typography>
									<Typography className={classes.detailsText}>
										Зовсім без теорії неможливо, АЛЕ ти закріпиш свої знання на практиці під час роботи над реальною вакансією. Ти засвоїш всі програми, якими рекрутери користуються щодня, проведеш інтерв’ю з кандидатами.
									</Typography>
								</li>
								<li className={classes.detailsListItem}>
									<Typography className={classes.detailsSubTitle}>
										Підтримка 24/7
									</Typography>
									<Typography className={classes.detailsText}>
										Спілкуйся у чаті з іншими студентами курсу та отримуй відповіді на свої питання від куратора курсу в той момент, коли вони виникають.
									</Typography>
								</li>
								<li className={classes.detailsListItem}>
									<Typography className={classNames(classes.detailsSubTitle, classes.fullWidth)}>
										Бери участь у щотижневих сесіях з питань та відповідей
									</Typography>
									<Typography className={classes.detailsText}>
										Приходь на щотижневі дзвінки з твоїм куратором, задавай питання та доповнюй свої знання. Усі наші куратори є практикуючими рекрутерами, тож вони відповідатимуть на твої питання на підставі практичного досвіду.
									</Typography>
								</li>
								<li className={classes.detailsListItem}>
									<Typography className={classNames(classes.detailsSubTitle, classes.fullWidth)}>
										Залишайся в контакті
									</Typography>
									<Typography className={classes.detailsText}>
										Ми не залишимо тебе самого навіть після завершення навчання! Ми підтримуємо своїх студентів та допомагаємо з побудовою професійних зв'язків у галузі.
									</Typography>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</Container>
		</section>
	);
};

export default About;
