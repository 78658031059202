import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Container, Typography} from '@mui/material';
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import { Faq as IFaq } from '../../../../interfaces';
import useStyles from './styles';

interface Props {
	faqList: IFaq[];
}

const Faq: React.FC<Props> = ({ faqList }: Props) => {
	const classes = useStyles();
	return (
		<section id="faq" className={classes.root}>
			<Container maxWidth="xl">
				<Typography className={classes.sectionTitleText}>НАЙЧАСТІШІ ЗАПИТАННЯ</Typography>
				<div className={classes.faqList}>
					{faqList.map(({ id, question, answer }, i) => (
						<Accordion key={id}>
							<AccordionSummary
								expandIcon={<AddRoundedIcon sx={{ fontSize: { md: '30px' }}} fontSize="medium" />}
							>
								<Typography className={classes.faqItemTitle}>{question}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography className={classes.faqItemText}>{answer}</Typography>
							</AccordionDetails>
						</Accordion>
					))}
				</div>
			</Container>
		</section>
	);
};

export default Faq;
