import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Link, Typography } from '@mui/material';

import useStyles from '../PrivacyPolicy/styles';
import classNames from "classnames";

const Agreement: React.FC = () => {
	const classes = useStyles();
	return (
		<section id="price-plans" className={classes.root}>
			<Container maxWidth="lg">
				<Typography className={classes.sectionTitleText}>Договір публічної оферти</Typography>

				<Typography className={classes.subTitle1}>1. Загальні положення</Typography>
				<Typography className={classes.textDefault}>
					1.1 Фізична особа-підприємець Мясникова Яніна Іванівна, надалі іменоване «Виконавець», публікує дану публічну оферту про надання послуг.
				</Typography>
				<Typography className={classes.textDefault}>
					1.2 Відповідно до статей 633, 641 Цивільного Кодексу України даний документ, що розміщений в мережі Інтернет за адресою
					{' '}
					<Link to="https://courses.wootitude.com/agreement" component={RouterLink} className={classes.link}>https://courses.wootitude.com/agreement</Link>
					{' '}
					є публічною офертою, і в разі вчинення фізичною особою дій, які свідчать про її акцепт, є обов’язковою до виконання для Виконавця і цієї фізичної особи, в подальшому іменується «Замовник». Оплата Замовником послуг Виконавця є повним і безумовним акцептом оферти, та вважається укладенням Договору на умовах, що викладені в даній оферті.
				</Typography>
				<Typography className={classes.textDefault}>
					1.3 В даній оферті наведені нижче терміни вживаються в такому значенні:
				</Typography>
				<ul className={classes.list}>
					<li className={classes.listItem}>
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							«Замовник» – особа, що сплатила вартість послуг, які надаються Виконавцем і є Стороною цього Договору.
						</Typography>
					</li>
					<li className={classes.listItem}>
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							«Пропозиції» – публічна пропозиція Виконавця, адресована будь-якій фізичній або юридичній особі укласти з ним договір про надання послуг на існуючих умовах, що містяться в пропозиції.
						</Typography>
					</li>
					<li className={classes.listItem}>
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							«Акцепт» – повне і безумовне прийняття Замовником умов оферти.
						</Typography>
					</li>
				</ul>

				<Typography className={classes.subTitle1}>2. Предмет Договору</Typography>
				<Typography className={classes.textDefault}>
					2.1. Згідно з умовами цього Договору Виконавець надає послуги з організації та проведення навчання (відповідає КВЕД
					{' '}
					<Link to="http://kved.ukrstat.gov.ua/KVED2010/85/KVED10_85_59.html" target="_blank" component={RouterLink} className={classes.link}>
						63.99
					</Link>
					{' '}
					інші види освіти, н.в.і.у.) Замовника відповідно до Програми навчального курсу, а Замовник зобов’язується оплатити надані послуги відповідно до умов цього Договору.
				</Typography>
				<Typography className={classes.textDefault}>
					2.2. Перелік послуг, що надаються Виконавцем, вказаний на Інтернет-сайті Виконавця за адресою
					{' '}
					<Link to="https://courses.wootitude.com" target="_blank" component={RouterLink} className={classes.link}>
						https://courses.wootitude.com/
					</Link>
					{' '}
					.
				</Typography>

				<Typography className={classes.subTitle1}>3. Вартість послуг та порядок оплати</Typography>
				<Typography className={classes.textDefault}>
					3.1. Оплата послуг за цим Договором здійснюється шляхом попередньої оплати Замовником вартості послуг.
				</Typography>
				<Typography className={classes.textDefault}>
					3.2. Вартість послуг визначається навчальною програмою курсу, розміщеною на Інтернет-сайті Виконавця за адресою
					{' '}
					<Link to="https://courses.wootitude.com" target="_blank" component={RouterLink} className={classes.link}>
						https://courses.wootitude.com/
					</Link>
					{' '}
					.
				</Typography>
				<Typography className={classes.textDefault}>
					3.3. Оплата може бути внесена у будь-який час перед початком прослуховування курсу навчання.
				</Typography>
				<Typography className={classes.textDefault}>
					3.4. У разі дострокового розірвання Договору з ініціативи Замовника, кошти, які були ним сплачені в якості плати за надання послуг повертаються з урахуванням наступних умов:
				</Typography>
				<ul className={classes.list}>
					<li className={classes.listItem}>
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							3.4.1 якщо Замовник відмовився від надання послуг не менше ніж за 24 години до початку доступу до курсу навчання – сплачені кошти повертаються Замовнику в повному обсязі в тижневий срок
						</Typography>
					</li>
				</ul>
				<Typography className={classes.textDefault}>
					3.5. Відмова від надання послуг повинна бути спрямована Замовником на електронну адресу Виконавця, яка зазначена у останньому розділі цього Договору. В цьому випадку Виконавець має право пересвідчитись в намірі Замовника розірвати Договір, зв’язавшись з ним за допомогою телефонного зв’язку або іншим способом.
				</Typography>
				<Typography className={classes.textDefault}>
					3.6. Розрахунки проводяться в безготівковій формі в національній валюті України або в іноземній валюті, конвертовано у національну валюту за курсом Національного банку України, встановленого на дату проведення платежу.
				</Typography>
				<Typography className={classes.textDefault}>
					3.7 Оферта має силу акта про надання послуг і прийняття послуги здійснюється без підписання відповідного акта
				</Typography>
				<Typography className={classes.textDefault}>
					3.8. Пропуск заняття Замовником не є підставою для повернення йому коштів.
				</Typography>

				<Typography className={classes.subTitle1}>4. Дія Договору</Typography>
				<Typography className={classes.textDefault}>
					4.1. Договір набуває чинності з моменту акцепту оферти і діє до моменту припинення Договору.
				</Typography>
				<Typography className={classes.textDefault}>
					4.2. У разі розірвання Договору з ініціативи однієї із Сторін, порядок розрахунків регулюється даним Договором.
				</Typography>
				<Typography className={classes.textDefault}>
					4.3. Цей Договір може бути розірваний достроково з ініціативи однієї із Сторін на підставах, передбачених чинним законодавством України. Сторона, яка ініціює розірвання даного Договору, зобов’язана повідомити про це іншу Сторону (шляхом надсилання електронного листа) не менше ніж за 5 (п’ять) днів до передбачуваної дати розірвання Договору. Сторони погодили, у разі дострокового припинення дії цього Договору за ініціативи Замовника після старту занять, грошові кошти останньому не повертаються.
				</Typography>

				<Typography className={classes.subTitle1}>5. Права і обов'язки Сторін</Typography>
				<Typography className={classes.textDefault}>
					5.1. Виконавець зобов’язується:
				</Typography>
				<ul className={classes.list}>
					<li className={classes.listItem}>
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							5.1.1. Надавати Замовнику послуги, відповідно до цього Договору та Програми, зазначеної на сайті Виконавця
							{' '}
							<Link to="https://courses.wootitude.com" target="_blank" component={RouterLink} className={classes.link}>
								https://courses.wootitude.com/
							</Link>
							{' '}
							.
						</Typography>
					</li>
				</ul>
				<Typography className={classes.textDefault}>
					5.2. Замовник зобов’язується:
				</Typography>
				<ul className={classes.list}>
					<li className={classes.listItem}>
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							5.2.1. Оплатити в повному обсязі вартість послуг на умовах, встановлених цим Договором.
						</Typography>
					</li>
					<li className={classes.listItem}>
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							5.2.2. Дотримуватись встановлених Виконавцем вимог, правил та інших умов надання послуг.
						</Typography>
					</li>
					<li className={classes.listItem}>
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							5.2.3. Не використовувати та не поширювати жодним способом отримані матеріали навчального курсу з будь якою метою.
						</Typography>
					</li>
				</ul>
				<Typography className={classes.textDefault}>
					5.3. Виконавець має право:
				</Typography>
				<ul className={classes.list}>
					<li className={classes.listItem}>
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							5.3.1. Не надавати Замовнику послуги у разі, якщо Замовник не пред’явив Виконавцю підтвердження їх оплати (квитанцію про оплату, чек, інший розрахунковий документ).
						</Typography>
					</li>
					<li className={classes.listItem}>
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							5.3.2. У разі необхідності, вносити зміни в порядок, час, строки надання послуг (зокрема, змінюючи розклад). Про зміни Виконавець завчасно повідомляє Замовника за допомогою розміщення відповідного оголошення загальнодоступним для огляду способом та (або) на Інтернет-сайті Виконавця або шляхом направлення відповідного електронного листа (email) / повідомлення, не пізніше ніж за 1 (один) календарний день до настання відповідних змін в розкладі.
						</Typography>
					</li>
					<li className={classes.listItem}>
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							5.3.3. Отримувати від Замовника інформацію, необхідну для надання послуг за цим Договором.
						</Typography>
					</li>
				</ul>
				<Typography className={classes.textDefault}>
					5.4. Замовник має право:
				</Typography>
				<ul className={classes.list}>
					<li className={classes.listItem}>
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							5.4.1. Користуватися послугами Виконавця протягом терміну, передбаченого Програмою навчання і цим Договором.
						</Typography>
					</li>
					<li className={classes.listItem}>
						<Typography className={classNames(classes.textDefault, classes.listItemSubText)}>
							5.4.2. Отримувати необхідну і достовірну інформацію про графік роботи Виконавця і послуги.
						</Typography>
					</li>
				</ul>

				<Typography className={classes.subTitle1}>6. Порядок і умови надання послуг</Typography>
				<Typography className={classes.textDefault}>
					6.1. Місце надання послуг за посиланням
					{' '}
					<Link to="https://wootitude-course.kwiga.com/" target="_blank" component={RouterLink} className={classes.link}>
						https://wootitude-course.kwiga.com/
					</Link>
					{' '}
					онлайн.
				</Typography>
				<Typography className={classes.textDefault}>
					6.2. Перед початком заняття Замовник пред’являє Виконавцю документ, що підтверджує оплату послуг (квитанцію про оплату, чек або інший розрахунковий документ).
				</Typography>
				<Typography className={classes.textDefault}>
					6.3. Виконавець надає Замовнику обмежене, персональне і непередаване право на використання послуг і будь-яких матеріалів або інформації, отриманих в процесі навчання за курсом. Матеріали і будь-яку інформацію, що надаються в рамках наданої послуги, Замовник може використовувати тільки для особистого некомерційного використання.
				</Typography>

				<Typography className={classes.subTitle1}>7. Відповідальність сторін</Typography>
				<Typography className={classes.textDefault}>
					7.1. Замовник повністю приймає на себе відповідальність за стан свого здоров’я і погоджується з тим, що не має права вимагати від Виконавця будь-якої компенсації матеріального збитку і шкоди, заподіяної його здоров’ю.
				</Typography>
				<Typography className={classes.textDefault}>
					7.2. У разі заподіяння Замовником шкоди майну Виконавця, останній має право вимагати від Замовника повного відшкодування такої шкоди. Під шкодою Сторони розуміють порушення авторського права Виконавця, яке виражається у розповсюдженні відео/аудіо текстового чи будь-якого іншого матеріалу Виконавцю без згоди останнього.
				</Typography>
				<Typography className={classes.textDefault}>
					7.3. При настанні обставин непереборної сили, які виходять за межі розумного передбачення і контролю Сторін цього Договору, Сторони звільняються від відповідальності за невиконання зобов’язань, які повинні були бути виконані в період дії таких обставин.
				</Typography>
				<Typography className={classes.textDefault}>
					7.4. Сторони погоджуються, що Виконавець не гарантує будь-яких фінансових чи інших результатів від застосування Замовником в своїй діяльності послуг, придбаних у Замовника.
				</Typography>
				<Typography className={classes.textDefault}>
					7.5. У разі порушення Замовником умов цього Договору та використання матеріалів курсу не в особистих цілях, Виконавець має право розірвати цей Договір в односторонньому порядку, заблокувати Замовнику доступ до навчального курсу, і в судовому порядку вимагати відшкодування збитків, завданих такими діями Замовника. В цьому випадку сплачені кошти Замовнику не повертаються.
				</Typography>

				<Typography className={classes.subTitle1}>8. Конфіденційність Договору</Typography>
				<Typography className={classes.textDefault}>
					8.1 Сторони домовились, що інформація та відомості, які стосуються цього Договору є конфіденційними і не можуть передаватися третім особам без попередньої письмової згоди іншої Сторони, крім випадків, коли така передача пов’язана з отриманням офіційних документів для виконання Договору або сплати податків, інших обов’язкових платежів, а також у випадках, передбачених законодавством.
				</Typography>
				<Typography className={classes.textDefault}>
					8.2 Сторони несуть відповідальність за законодавством України у разі розголошення конфіденційної інформації, яка стане їм відома при виконанні умов цього Договору.
				</Typography>
				<Typography className={classes.textDefault}>
					8.3 Жодна із Сторін не має права передавати свої права та обов’язки за Договором іншій особі без письмової згоди іншої Сторони.
				</Typography>
				<Typography className={classes.textDefault}>
					8.4 Сторони домовилися, фото і відео матеріали, які створені Виконавцем в процесі надання послуг, можуть використовуватися останнім для публікації в будь-яких джерелах інформації (в тому числі в соціальних мережах, на сайті Виконавця, тощо) з метою поширення рекламних відомостей про послуги, які їм надаються.
				</Typography>
				<Typography className={classes.textDefault}>
					8.5 Замовник підтверджує свою згоду на обробку Виконавцем його персональних даних, тобто на вчинення наступних дій щодо персональних даних Замовника: збір, систематизацію, внесення в бази даних Виконавця, в тому числі в електронні, накопичення, зберігання, уточнення, а також на подальше використання і поширення Виконавцем персональних даних відповідно до положень Закону України «Про захист персональних даних».
				</Typography>
				<Typography className={classes.textDefault}>
					8.6 Сторони домовились, що Виконавець буде здійснювати збір і накопичення персональних даних Замовника на паперових та/або електронних носіях, в тому числі шляхом внесення їх в базу даних Виконавця.
				</Typography>
				<Typography className={classes.textDefault}>
					8.7. Питання, не врегульовані цим Договором, регулюються інформацією, вказаною на Інтернет-сайті Виконавця за адресою
					{' '}
					<Link to="https://courses.wootitude.com/" target="_blank" component={RouterLink} className={classes.link}>
						https://courses.wootitude.com/
					</Link>
					{' '}
					і чинним законодавством України.
				</Typography>
				<Typography className={classes.textDefault}>
					8.8. Даний Договір розміщений на сайті Виконавця та викладений українською мовою.
				</Typography>

				<Typography className={classes.subTitle1}>9. Вирішення спорів</Typography>
				<Typography className={classes.textDefault}>
					9.1 У разі виникнення суперечок або розбіжностей Сторони зобов’язуються вирішувати їх шляхом переговорів.
				</Typography>
				<Typography className={classes.textDefault}>
					9.2 У разі недосягнення Сторонами згоди, спори вирішуються в судовому порядку.
				</Typography>

				<Typography className={classes.subTitle1}>10. Реквізити Виконавця:</Typography>
				<Typography className={classes.textDefault}>
					Фізична особа-підприємець Мясникова Яніна Іванівна
				</Typography>
				<Typography className={classes.textDefault}>
					ІПН: 3308503306
				</Typography>
				<Typography className={classes.textDefault}>
					Запис про проведення державної реєстрації фізичної особи-підприємця
				</Typography>
				<Typography className={classes.textDefault}>
					від 13.02.2018 №24800000000205544
				</Typography>
				<Typography className={classes.textDefault}>
					п/р: UA223515330000026002052106601 в АТ КБ "ПРИВАТБАНК"
				</Typography>
				<Typography className={classes.textDefault}>
					Адреса: Україна, 61111,Харківська область, місто Харків, вул.Познанська,будинок 12, квартира 20.
				</Typography>
				<Typography className={classes.textDefault}>
					yanina.miasnykova@wootitude.com
				</Typography>
				<Typography className={classes.textDefault}>
					тел. 0689927726
				</Typography>

			</Container>
		</section>
	);
};

export default Agreement;
