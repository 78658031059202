import React from 'react';
import { useNavigate } from "react-router-dom";
import {Button, Container, Typography} from '@mui/material';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';

import useStyles from './styles';

const FirstScreen: React.FC = () => {
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<section id="main" className={classes.root}>
			<Container maxWidth="xl">
				<div className={classes.content}>
					<div className={classes.sectionImgWrapper}>
						<img src="/assets/first-screen-image.svg" alt="" className={classes.sectionImg} />
					</div>
					<div className={classes.sectionInfo}>
						<Typography variant="h1" className={classes.title}>It Recruiter</Typography>
						<Typography variant="body2" className={classes.subTitle}>Навчайся ефективно вирішувати завдання ІТ бізнесу та стань високозатребуваним рекрутером, незалежно від ситуації на ринку.</Typography>
						<Button
							variant="contained"
							className={classes.actionBtn}
							onClick={() => navigate('/#price-plans')}
						>
							Почати навчання
						</Button>
						<div className={classes.details}>
							<div className={classes.detailsItem}>
								<div className={classes.itemHead}>
									<Typography className={classes.itemTitle}>Експертиза та досвід</Typography>
									<StarRateRoundedIcon className={classes.itemIcon} />
								</div>
								<Typography className={classes.itemText}>
									За 3 місяці опануй всі етапи цієї професії за підтримки досвідчених експертів: від особистого бренду рекрутера до використання провідних інструментів рекрутингу.
								</Typography>
							</div>
							<div className={classes.detailsItem}>
								<div className={classes.itemHead}>
									<Typography className={classes.itemTitle}>Практичний підхід</Typography>
									<SettingsRoundedIcon className={classes.itemIcon} />
								</div>
								<Typography className={classes.itemText}>Працюй над справжньою вакансією, проводь реальні співбесіди та отримуй гроші $$$ за закриті позиції під час навчання!</Typography>
							</div>
							<div className={classes.detailsItem}>
								<div className={classes.itemHead}>
									<Typography className={classes.itemTitle}>Стань частиною команди</Typography>
									<PersonAddAltRoundedIcon className={classes.itemIcon} />
								</div>
								<Typography className={classes.itemText}>Ми розробляємо цей курс для себе, оскільки маємо великий потік вакансій, та нам потрібні талановиті люди в команді.</Typography>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</section>
	);
};

export default FirstScreen
