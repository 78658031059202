import React from 'react';
import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import classNames from 'classnames';
import InstagramIcon from '../Icons/Instagram';
import LinkedInIcon from '../Icons/LinkedInIcon';
import TelegramIcon from '../Icons/Telegram';
import FacebookIcon from '../Icons/Facebook';

const useStyles = makeStyles(({ palette }: Theme) => ({
	root: {
		display: 'flex',
		flexShrink: 0,

		'&.medium': {
			gap: '40px',
		},
		'&.small': {
			gap: '26px',
		},
	},
	link: {
		display: 'block',
		width: '40px',
		height: '40px',
		borderRadius: '40px',
		background: palette.secondary.contrastText,
		color: '#131112',

		'&:hover': {
			background: palette.secondary.main,
			color: palette.secondary.contrastText,
		},
	},
}));

const links = [
	{
		href: 'https://www.instagram.com/wootitude/',
		Icon: InstagramIcon,
	},
	{
		href: 'https://www.linkedin.com/company/wootitude',
		Icon: LinkedInIcon,
	},
	{
		href: 'https://t.me/Wooti_talk',
		Icon: TelegramIcon,
	},
	{
		href: 'https://www.facebook.com/wootitude/',
		Icon: FacebookIcon,
	},
];

export interface Props {
	size?: 'medium' | 'small';
}

const SocialIconsList: React.FC<Props> = ({ size = 'medium' }: Props) => {
	const classes = useStyles();

	return (
		<ul className={classNames(classes.root, size)}>
			{links.map(({href, Icon}) => (
				<li key={href}>
					<Link href={href} target="_blank" className={classes.link}>
						<Icon />
					</Link>
				</li>
			))}
		</ul>
	)
};

export default SocialIconsList;