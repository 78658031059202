
// eslint-disable-next-line import/no-anonymous-default-export
export default {
	styleOverrides: {
		root: ({ theme }: any) => ({
			backgroundColor: 'transparent',
			borderBottom: '1px solid rgba(255, 255, 252, 0.60)',
			borderRadius: '0!important',

			'&.Mui-expanded': {
				margin: 0,
			},

			'&:first-of-type': {
				borderTop: '1px solid rgba(255, 255, 252, 0.60)',
			},

			'& .MuiButtonBase-root': {
				padding: 0,

				'&:hover': {
					'& .MuiAccordionSummary-expandIconWrapper': {
						color: '#131112',
						backgroundColor: '#EBFF33',
					}
				},
			},
			'& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded': {
				padding: '12px 0',
				margin: 0,

				[theme.breakpoints.up('md')]: {
					padding: '30px 0',
				},
			},
			'& .MuiAccordionSummary-expandIconWrapper': {
				borderRadius: '30px',
				color: '#D9D9D9',
				padding: 0,

				'&.Mui-expanded': {
					transform: 'rotate(45deg)'
				},
			},
			'& .MuiAccordionDetails-root': {
				padding: '0 0 12px',

				[theme.breakpoints.up('md')]: {
					padding: '0 0 48px',
				},
			},
		}),
	},
};
