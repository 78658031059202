import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) => ({
	root: {
		padding: '32px 0 60px',

		[theme.breakpoints.up('md')]: {
			padding: '80px 0 197px',
		},
	},
	sectionTitleText: {
		fontFamily: 'Montserrat Alternates, sans-serif',
		fontSize: '24px',
		fontWeight: 700,
		textTransform: 'uppercase',

		[theme.breakpoints.up('md')]: {
			fontSize: '54px',
		},
	},
	faqList: {
		padding: '32px 0 0',

		[theme.breakpoints.up('md')]: {
			padding: '64px 0 0',
		},
	},
	faqItemTitle: {
		fontFamily: 'Montserrat Alternates, sans-serif',
		fontSize: '20px',
		fontWeight: 700,
		lineHeight: 'normal',

		[theme.breakpoints.up('md')]: {
			fontSize: '24px',
			fontWeight: 600,
		},
	},
	faqItemText: {
		opacity: .8,
		fontSize: '12px',
		lineHeight: '20px',
		padding: '0 46px 0 0',

		[theme.breakpoints.up('md')]: {
			fontSize: '16px',
			lineHeight: '25px',
			padding: '0 90px 0 0',
		},
	},
}));
