import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) => ({
	benefitList: {
		padding: '0 0 26px',
	},
	benefitListItem: {
		padding: '6px 0',

		'&:not(:last-child)': {
			borderBottom: '1px solid rgba(255, 255, 255, 0.10)',
		},
	},
	benefitItemSummary: {
		position: 'relative',
	},
	checkIcon: {
		position: 'absolute',
		top: '3px',
		left: 0,
		color: '#EBFF33',
		width: '18px',
		height: '18px',

		'&.inactive': {
			color: theme.palette.secondary.contrastText,
			opacity: .4,
		}
	},
	benefitItemLabel: {
		fontSize: '16px',
		padding: '0 0 0 26px',

		'&.inactive': {
			opacity: .4,
		}
	},
	subList: {
		padding: '6px 0 0 26px',
	},
	subListItem: {
		'&:not(:last-child)': {
			margin: '0 0 2px',
		},
	},
	subListText: {
		fontSize: '14px',
		lineHeight: 'normal',

		'&:before': {
			content: '\'— \'',
			color: '#EBFF33',
		},

		'&.inactive': {
			opacity: .4,

			'&:before': {
				color: theme.palette.secondary.contrastText,
			},
		},
	},
}));
