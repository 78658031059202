import {TypographyOptions} from '@mui/material/styles/createTypography';
import palette from './palette';

const typography: TypographyOptions = {
	fontFamily: ['Lato', 'sans-serif'].join(','),

	h1: {
		fontFamily: 'Montserrat Alternates, sans-serif',
		color: palette.primary?.contrastText,
		fontSize: '80px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: 'normal',
	},
	h2: {
		fontFamily: 'Montserrat Alternates, sans-serif',
		color: palette.primary?.contrastText,
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: 'normal',
		fontSize: '54px',
		textTransform: 'uppercase',
	},
	body1: {
		color: palette.primary?.contrastText,
	},
	body2: {
		color: `${palette.primary?.contrastText}!important`,
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: 'normal',
	},
};

export default typography;