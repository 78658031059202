import React, { useState } from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Button, Container, Typography} from '@mui/material';

import { CourseProgram as ICourseProgram } from '../../../../interfaces';
import useStyles from './styles';
import AddRoundedIcon from "@mui/icons-material/AddRounded";

interface Props {
	program: ICourseProgram
}

const CourseProgram: React.FC<Props> = ({ program = [] }: Props) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	const visibleProgram = open ? program : program.slice(0, 8);

	return (
		<section className={classes.root}>
			<Container maxWidth="xl">
				<div className={classes.content}>
					<div className={classes.sectionTitle}>
						<Typography className={classes.sectionTitleText}>ПРОГРАМА КУРСУ</Typography>
					</div>
					<div className={classes.courseProgram}>
						<div className={classes.programList}>
							{visibleProgram.map(({ id, title, topics}, i) => (
								<Accordion key={id}>
									<AccordionSummary
										expandIcon={<AddRoundedIcon sx={{ fontSize: { md: '30px' }}} fontSize="medium" />}
									>
										<div>
											<Typography className={classes.programItemNumber}>{`${i}. Модуль`}</Typography>
											<Typography className={classes.programItemTitle}>{title}</Typography>
										</div>
									</AccordionSummary>
									<AccordionDetails>
										{!!topics.length && (
											<ul className={classes.programSubList}>
												{topics.map(topic => (
													<li key={topic} className={classes.programSubListItem}>
														<Typography className={classes.programSubListText}>{topic}</Typography>
													</li>
												))}
											</ul>
										)}
									</AccordionDetails>
								</Accordion>
							))}
						</div>
						<Button variant="contained" onClick={() => setOpen(v => !v)}>
							{open ? 'Згорнути' : 'Показати всю програму'}
						</Button>
					</div>
				</div>
			</Container>
		</section>
	)
};

export default CourseProgram
