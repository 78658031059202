import { GET_LANDING_DATA } from './api-client';
import { ApolloError, useQuery } from '@apollo/client';

import { CourseProgram, PricePlan, Testimonial, Faq } from '../interfaces';

export interface State {
	data: {
		courseProgram: CourseProgram;
		pricePlans: PricePlan[];
		testimonials: Testimonial[];
		faqList: Faq[];
	};
	loading: boolean;
	error: ApolloError | undefined;
}

const initialValue = {
	courseProgram: [],
	pricePlans: [],
	testimonials: [],
	faqList: [],
}

const useLandingData = (): State => {
	const { loading, error, data } = useQuery(GET_LANDING_DATA);

	return {
		loading,
		error,
		data: {
			courseProgram: data?.courseModules || initialValue.courseProgram,
			pricePlans: data?.pricePlans || initialValue.pricePlans,
			testimonials: data?.testimonials || initialValue.testimonials,
			faqList: data?.faqAll || initialValue.faqList,
		},
	};
};

export default useLandingData;
