import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) => ({
	root: {
		padding: '32px 0 20px',

		[theme.breakpoints.up('md')]: {
			padding: '80px 0 68px',
		},
		[theme.breakpoints.up('lg')]: {
			padding: '80px 0 100px',
		},
	},
	sectionTitleText: {
		fontFamily: 'Montserrat Alternates, sans-serif',
		fontSize: '24px',
		fontWeight: 700,
		textTransform: 'uppercase',

		[theme.breakpoints.up('md')]: {
			fontSize: '54px',
		}
	},
	priceList: {
		padding: '32px 0 0',
		flexWrap: 'wrap',

		[theme.breakpoints.up('md')]: {
			display: 'flex',
			justifyContent: 'space-between',
			gap: '20px',
			padding: '64px 0 0',
		},
		[theme.breakpoints.up('lg')]: {
			flexWrap: 'nowrap',
		},
	},
	selectDropdown: {
		'& .MuiPaper-root': {
			background: '#211F20',

			'& .Mui-selected, & .MuiMenuItem-root:hover': {
				background: '#646262',
			},
		},
	},
	dialogCloseBtn: {
		position: 'absolute',
		top: '0px',
		right: '0px',
		color: theme.palette.primary.contrastText,

		'&:hover': {
			color: '#EBFF33',
		},
	},
	privacyField: {
		alignItems: 'flex-start',
		marginTop: '12px',

		'& .MuiCheckbox-root': {
			top: '-11px',
			color: '#EBFF33',
		},
	},
	privacyLabel: {
		color:'rgba(255, 255, 255, 0.60)',
		fontSize: '12px',
		lineHeight: '19px',

		'&.error': {
			color: '#FF4B4B',
		},
	},
	dialogActionsWrapper: {
		display:'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	errorMessage: {
		padding: '10px 0 0',
		color: '#FF4B4B',
		fontSize: '12px',
	},
	textLink: {
		color: 'inherit',
		fontSize: 'inherit',
		textDecoration: 'underline',
	},
}));
