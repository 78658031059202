import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export default makeStyles((theme: Theme) => ({
	root: {
		position: 'relative',
		overflow: 'hidden',
		padding: '40px 0 20px',

		[theme.breakpoints.up('md')]: {
			padding: '140px 0 100px',
		},
	},
	content: {
		maxWidth: '100%',
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',

		[theme.breakpoints.up('lg')]: {
			flexWrap: 'nowrap',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'flex-end',
		}
	},
	sectionInfo: {
		maxWidth: '100%',
		width: '100%',
		flexShrink: 0,

		[theme.breakpoints.up('lg')]: {
			width: '800px',
		},
	},
	title: {
		color: theme.palette.primary.contrastText,
		fontFamily: 'Montserrat Alternates, sans-serif',
		fontSize: '34px',
		fontWeight: 700,

		[theme.breakpoints.up('md')]: {
			fontSize: '80px',
			margin: '0 0 30px'
		}
	},
	subTitle: {
		fontFamily: 'Lato, sans-serif',
		color: theme.palette.primary.contrastText,
		fontSize: '12px',
		opacity: '.8',
		lineHeight: '25px',
		margin: '0 0 20px',

		[theme.breakpoints.up('md')]: {
			fontSize: '16px',
			margin: '0 0 40px'
		},
		[theme.breakpoints.up('lg')]: {
			maxWidth: '500px',
		},
	},
	actionBtn: {
		width: '100%',
		maxWidth: '100%',

		[theme.breakpoints.up('md')]: {
			maxWidth: '250px',
		},
	},
	details: {
		display: 'none',
		gap: '20px',
		padding: '70px 0 0',

		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	detailsItem: {
		flexBasis: '33%',
		padding: '20px',
		borderRadius: '10px',
		background: 'rgba(66, 64, 65, .3)',
		backdropFilter: 'blur(45px)',
	},
	itemHead: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '0 0 13px'
	},
	itemTitle: {
		fontFamily: 'Montserrat Alternates, sans-serif',
		fontSize: '16px',
		fontWeight: 700,
		padding: '0 20px 0 0',
	},
	itemIcon: {
		color: '#EBFF33',
		width: '32px',
		height: '32px',
	},
	itemText: {
		fontSize: '14px',
		fontWeight: 400,
		lineHeight: '20px',
	},
	sectionImgWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexGrow: 1,
		padding: '0 0 25px',

		[theme.breakpoints.up('lg')]: {
			order: 2,
			height: '571px',
			padding: 0,
		}
	},
	sectionImg: {
		maxWidth: '100%',
		maxHeight: '100%',
	},
}));
